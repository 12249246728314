import React from 'react';

const UsedTransmissions = () => {
  return (
    <div>
      <>
        <div className="site-primary" id="main">
          <div className="site-content" id="content">
            <div
              data-elementor-type="wp-page"
              data-elementor-id={4610}
              className="elementor elementor-4610"
            >
              <section
                className="elementor-section elementor-top-section elementor-element elementor-element-cabc489 elementor-section-height-min-height elementor-section-boxed elementor-section-height-default elementor-section-items-middle"
                data-id="cabc489"
                data-element_type="section"
                data-settings='{"background_background":"classic","_ha_eqh_enable":false}'
              >
                <div className="elementor-background-overlay" />
                <div className="elementor-container elementor-column-gap-default">
                  <div
                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4099aac"
                    data-id="4099aac"
                    data-element_type="column"
                  >
                    <div className="elementor-widget-wrap elementor-element-populated">
                      <div
                        className="elementor-element elementor-element-aa76d24 elementor-widget elementor-widget-heading"
                        data-id="aa76d24"
                        data-element_type="widget"
                        data-widget_type="heading.default"
                      >
                        <div className="elementor-widget-container">
                          <style
                            dangerouslySetInnerHTML={{
                              __html:
                                "\n\t\t\t\t\t\t\t\t\t\t\t\t/*! elementor - v3.22.0 - 26-06-2024 */\n\t\t\t\t\t\t\t\t\t\t\t\t.elementor-heading-title {\n\t\t\t\t\t\t\t\t\t\t\t\t\tpadding: 0;\n\t\t\t\t\t\t\t\t\t\t\t\t\tmargin: 0;\n\t\t\t\t\t\t\t\t\t\t\t\t\tline-height: 1\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-heading .elementor-heading-title[class*=elementor-size-]>a {\n\t\t\t\t\t\t\t\t\t\t\t\t\tcolor: inherit;\n\t\t\t\t\t\t\t\t\t\t\t\t\tfont-size: inherit;\n\t\t\t\t\t\t\t\t\t\t\t\t\tline-height: inherit\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-heading .elementor-heading-title.elementor-size-small {\n\t\t\t\t\t\t\t\t\t\t\t\t\tfont-size: 15px\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-heading .elementor-heading-title.elementor-size-medium {\n\t\t\t\t\t\t\t\t\t\t\t\t\tfont-size: 19px\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-heading .elementor-heading-title.elementor-size-large {\n\t\t\t\t\t\t\t\t\t\t\t\t\tfont-size: 29px\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-heading .elementor-heading-title.elementor-size-xl {\n\t\t\t\t\t\t\t\t\t\t\t\t\tfont-size: 39px\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-heading .elementor-heading-title.elementor-size-xxl {\n\t\t\t\t\t\t\t\t\t\t\t\t\tfont-size: 59px\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t\t\t"
                            }}
                          />
                          <h2 className="elementor-heading-title elementor-size-default">
                            Used Transmissions
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section
                className="elementor-section elementor-top-section elementor-element elementor-element-ea049ac elementor-section-height-min-height elementor-section-boxed elementor-section-height-default elementor-section-items-middle"
                data-id="ea049ac"
                data-element_type="section"
                data-settings='{"_ha_eqh_enable":false}'
              >
                <div className="elementor-container elementor-column-gap-default">
                  <div
                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9d57c02"
                    data-id="9d57c02"
                    data-element_type="column"
                  >
                    <div className="elementor-widget-wrap elementor-element-populated">
                      <section
                        className="elementor-section elementor-inner-section elementor-element elementor-element-a53bb10 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                        data-id="a53bb10"
                        data-element_type="section"
                        data-settings='{"_ha_eqh_enable":false}'
                      >
                        <div className="elementor-container elementor-column-gap-default">
                          <div
                            className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-5840c7c"
                            data-id="5840c7c"
                            data-element_type="column"
                          >
                            <div className="elementor-widget-wrap elementor-element-populated">
                              <div
                                className="elementor-element elementor-element-e5dbb61 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                                data-id="e5dbb61"
                                data-element_type="widget"
                                data-widget_type="icon-list.default"
                              >
                                <div className="elementor-widget-container">
                                  <link
                                    rel="stylesheet"
                                    href="../assets/files/plugins/elementor/assets/css/widget-icon-list.min.css"
                                  />
                                  <ul className="elementor-icon-list-items">
                                    <li className="elementor-icon-list-item">
                                      <a href="/used-engines">
                                        <span className="elementor-icon-list-icon">
                                          <svg
                                            aria-hidden="true"
                                            className="e-font-icon-svg e-fas-dot-circle"
                                            viewBox="0 0 512 512"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm80 248c0 44.112-35.888 80-80 80s-80-35.888-80-80 35.888-80 80-80 80 35.888 80 80z"></path>
                                          </svg>{" "}
                                        </span>
                                        <span className="elementor-icon-list-text">
                                          Used Engines
                                        </span>
                                      </a>
                                    </li>
                                    <li className="elementor-icon-list-item">
                                      <a href="index.html@p=4610.html">
                                        <span className="elementor-icon-list-icon">
                                          <svg
                                            aria-hidden="true"
                                            className="e-font-icon-svg e-fas-dot-circle"
                                            viewBox="0 0 512 512"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm80 248c0 44.112-35.888 80-80 80s-80-35.888-80-80 35.888-80 80-80 80 35.888 80 80z"></path>
                                          </svg>{" "}
                                        </span>
                                        <span className="elementor-icon-list-text">
                                          Used Transmissions
                                        </span>
                                      </a>
                                    </li>
                                    <li className="elementor-icon-list-item">
                                      <a href="/used-auto-spare-parts">
                                        <span className="elementor-icon-list-icon">
                                          <svg
                                            aria-hidden="true"
                                            className="e-font-icon-svg e-fas-dot-circle"
                                            viewBox="0 0 512 512"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm80 248c0 44.112-35.888 80-80 80s-80-35.888-80-80 35.888-80 80-80 80 35.888 80 80z"></path>
                                          </svg>{" "}
                                        </span>
                                        <span className="elementor-icon-list-text">
                                          Used Auto Parts
                                        </span>
                                      </a>
                                    </li>
                                    <li className="elementor-icon-list-item">
                                      <a href="/rebuilt-engines">
                                        <span className="elementor-icon-list-icon">
                                          <svg
                                            aria-hidden="true"
                                            className="e-font-icon-svg e-fas-dot-circle"
                                            viewBox="0 0 512 512"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm80 248c0 44.112-35.888 80-80 80s-80-35.888-80-80 35.888-80 80-80 80 35.888 80 80z"></path>
                                          </svg>{" "}
                                        </span>
                                        <span className="elementor-icon-list-text">
                                          Rebuilt Engines
                                        </span>
                                      </a>
                                    </li>
                                    <li className="elementor-icon-list-item">
                                      <a href="/rebuilt-transmissions">
                                        <span className="elementor-icon-list-icon">
                                          <svg
                                            aria-hidden="true"
                                            className="e-font-icon-svg e-fas-dot-circle"
                                            viewBox="0 0 512 512"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm80 248c0 44.112-35.888 80-80 80s-80-35.888-80-80 35.888-80 80-80 80 35.888 80 80z"></path>
                                          </svg>{" "}
                                        </span>
                                        <span className="elementor-icon-list-text">
                                          Rebuilt Transmissions
                                        </span>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-c855a17 elementor-widget elementor-widget-image"
                                data-id="c855a17"
                                data-element_type="widget"
                                data-widget_type="image.default"
                              >
                                <div className="elementor-widget-container">
                                  <style
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        '\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t/*! elementor - v3.22.0 - 26-06-2024 */\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-image {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\ttext-align: center\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-image a {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tdisplay: inline-block\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-image a img[src$=".svg"] {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\twidth: 48px\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-image img {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tvertical-align: middle;\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tdisplay: inline-block\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t'
                                    }}
                                  />{" "}
                                  <img
                                    decoding="async"
                                    width={659}
                                    height={423}
                                    src="../assets/files/uploads/2024/02/image-1.png"
                                    className="attachment-large size-large wp-image-4563"
                                    alt=""
                                    srcSet="../assets/files/uploads/2024/02/image-1.png 659w, ../assets/files/uploads/2024/02/image-1-300x193.png 300w, ../assets/files/uploads/2024/02/image-1-600x385.png 600w, ../assets/files/uploads/2024/02/image-1-84x54.png 84w"
                                    sizes="(max-width: 659px) 100vw, 659px"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-dbebc9e"
                            data-id="dbebc9e"
                            data-element_type="column"
                          >
                            <div className="elementor-widget-wrap elementor-element-populated">
                              <div
                                className="elementor-element elementor-element-9b7ce14 elementor-widget elementor-widget-heading"
                                data-id="9b7ce14"
                                data-element_type="widget"
                                data-widget_type="heading.default"
                              >
                                <div className="elementor-widget-container">
                                  <h2 className="elementor-heading-title elementor-size-default">
                                    Used Transmissions
                                  </h2>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-34fe95b elementor-widget elementor-widget-text-editor"
                                data-id="34fe95b"
                                data-element_type="widget"
                                data-widget_type="text-editor.default"
                              >
                                <div className="elementor-widget-container">
                                  <style
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t/*! elementor - v3.22.0 - 26-06-2024 */\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-text-editor.elementor-drop-cap-view-stacked .elementor-drop-cap {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tbackground-color: #69727d;\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tcolor: #fff\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-text-editor.elementor-drop-cap-view-framed .elementor-drop-cap {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tcolor: #69727d;\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tborder: 3px solid;\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tbackground-color: transparent\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tmargin-top: 8px\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap-letter {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\twidth: 1em;\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\theight: 1em\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-text-editor .elementor-drop-cap {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tfloat: left;\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\ttext-align: center;\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tline-height: 1;\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tfont-size: 50px\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-text-editor .elementor-drop-cap-letter {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tdisplay: inline-block\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                    }}
                                  />{" "}
                                  Welcome to Motor Tiger Inc., your trusted
                                  destination for a diverse selection of reliable used
                                  transmissions. Our inventory is carefully curated to
                                  ensure quality and optimal performance, providing
                                  you with a cost-effective solution for your
                                  vehicle’s transmission needs.
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-f6e3c76 elementor-widget elementor-widget-heading"
                                data-id="f6e3c76"
                                data-element_type="widget"
                                data-widget_type="heading.default"
                              >
                                <div className="elementor-widget-container">
                                  <h2 className="elementor-heading-title elementor-size-default">
                                    Explore Our Wide Selection
                                  </h2>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-3a2c373 elementor-widget elementor-widget-text-editor"
                                data-id="3a2c373"
                                data-element_type="widget"
                                data-widget_type="text-editor.default"
                              >
                                <div className="elementor-widget-container">
                                  Discover a wide range of used transmissions,
                                  meticulously inspected to meet our stringent quality
                                  standards. Our commitment to excellence means you
                                  can browse confidently, knowing that each
                                  transmission has been rigorously vetted for
                                  reliability.{" "}
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-89d04cd elementor-widget elementor-widget-heading"
                                data-id="89d04cd"
                                data-element_type="widget"
                                data-widget_type="heading.default"
                              >
                                <div className="elementor-widget-container">
                                  <h2 className="elementor-heading-title elementor-size-default">
                                    Rigorous Inspection Process
                                  </h2>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-390b888 elementor-widget elementor-widget-text-editor"
                                data-id="390b888"
                                data-element_type="widget"
                                data-widget_type="text-editor.default"
                              >
                                <div className="elementor-widget-container">
                                  At Motor Tiger Inc., we understand the significance
                                  of a reliable transmission. That’s why every unit in
                                  our inventory undergoes a thorough inspection
                                  process. Our team of experts ensures that each
                                  transmission meets the highest standards for quality
                                  and performance.{" "}
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-559b9c0 elementor-widget elementor-widget-heading"
                                data-id="559b9c0"
                                data-element_type="widget"
                                data-widget_type="heading.default"
                              >
                                <div className="elementor-widget-container">
                                  <h2 className="elementor-heading-title elementor-size-default">
                                    Compatibility Guaranteed
                                  </h2>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-6a67bb4 elementor-widget elementor-widget-text-editor"
                                data-id="6a67bb4"
                                data-element_type="widget"
                                data-widget_type="text-editor.default"
                              >
                                <div className="elementor-widget-container">
                                  Our vast inventory offers a variety of options,
                                  ensuring compatibility with a wide range of
                                  vehicles. Finding the perfect fit for your vehicle
                                  has never been easier. Trust us to provide you with
                                  a transmission that not only meets your
                                  specifications but also exceeds your expectations.{" "}
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-64cf7ac elementor-widget elementor-widget-heading"
                                data-id="64cf7ac"
                                data-element_type="widget"
                                data-widget_type="heading.default"
                              >
                                <div className="elementor-widget-container">
                                  <h2 className="elementor-heading-title elementor-size-default">
                                    Peace of Mind
                                  </h2>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-ab680cb elementor-widget elementor-widget-text-editor"
                                data-id="ab680cb"
                                data-element_type="widget"
                                data-widget_type="text-editor.default"
                              >
                                <div className="elementor-widget-container">
                                  Experience peace of mind with our carefully
                                  inspected used transmissions. Our commitment to
                                  quality and performance means you can confidently
                                  invest in a transmission from Motor Tiger Inc.,
                                  knowing it will deliver the reliability your vehicle
                                  deserves.{" "}
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-0cc5f74 elementor-widget elementor-widget-heading"
                                data-id="0cc5f74"
                                data-element_type="widget"
                                data-widget_type="heading.default"
                              >
                                <div className="elementor-widget-container">
                                  <h2 className="elementor-heading-title elementor-size-default">
                                    Your Trusted Partner
                                  </h2>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-dc8e3ef elementor-widget elementor-widget-text-editor"
                                data-id="dc8e3ef"
                                data-element_type="widget"
                                data-widget_type="text-editor.default"
                              >
                                <div className="elementor-widget-container">
                                  We take pride in being your trusted partner for all
                                  your transmission needs. Our dedication to customer
                                  satisfaction and our reputation for offering
                                  reliable solutions set us apart in the industry.{" "}
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-ba4e605 elementor-widget elementor-widget-heading"
                                data-id="ba4e605"
                                data-element_type="widget"
                                data-widget_type="heading.default"
                              >
                                <div className="elementor-widget-container">
                                  <h2 className="elementor-heading-title elementor-size-default">
                                    Invest with Confidence
                                  </h2>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-9c3c0cf elementor-widget elementor-widget-text-editor"
                                data-id="9c3c0cf"
                                data-element_type="widget"
                                data-widget_type="text-editor.default"
                              >
                                <div className="elementor-widget-container">
                                  Choose a used transmission from Motor Tiger Inc. and
                                  invest with confidence. Our commitment to quality
                                  ensures that you receive a transmission that has
                                  been thoroughly vetted, offering optimal performance
                                  and peace of mind on the road.
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-04bf084 elementor-widget elementor-widget-heading"
                                data-id="04bf084"
                                data-element_type="widget"
                                data-widget_type="heading.default"
                              >
                                <div className="elementor-widget-container">
                                  <h2 className="elementor-heading-title elementor-size-default">
                                    Browse Our Inventory Today
                                  </h2>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-25dc839 elementor-widget elementor-widget-text-editor"
                                data-id="25dc839"
                                data-element_type="widget"
                                data-widget_type="text-editor.default"
                              >
                                <div className="elementor-widget-container">
                                  Explore our website to find the perfect used
                                  transmission for your vehicle. With our wide
                                  selection and rigorous inspection process, you can
                                  trust Motor Tiger Inc. to deliver the quality and
                                  reliability you deserve.{" "}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </section>
              <section
                className="elementor-section elementor-top-section elementor-element elementor-element-d0ae2db elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                data-id="d0ae2db"
                data-element_type="section"
                data-settings='{"background_background":"classic","_ha_eqh_enable":false}'
              >
                <div className="elementor-background-overlay" />
                <div className="elementor-container elementor-column-gap-default">
                  <div
                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-3d2ff67"
                    data-id="3d2ff67"
                    data-element_type="column"
                  >
                    <div className="elementor-widget-wrap elementor-element-populated">
                      <div
                        className="elementor-element elementor-element-e9745f3 elementor-align-center elementor-widget__width-auto elementor-widget elementor-widget-button"
                        data-id="e9745f3"
                        data-element_type="widget"
                        data-widget_type="button.default"
                      >
                        <div className="elementor-widget-container">
                          <div className="elementor-button-wrapper">
                            <a
                              className="elementor-button elementor-button-link elementor-size-sm"
                              href="index.html@p=4610.html#"
                            >
                              <span className="elementor-button-content-wrapper">
                                <span className="elementor-button-text">
                                  CALL US NOW
                                </span>
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div
                        className="elementor-element elementor-element-2a94bc2 elementor-align-center elementor-widget__width-auto elementor-widget elementor-widget-button"
                        data-id="2a94bc2"
                        data-element_type="widget"
                        data-widget_type="button.default"
                      >
                        <div className="elementor-widget-container">
                          <div className="elementor-button-wrapper">
                            <a
                              className="elementor-button elementor-button-link elementor-size-sm"
                              href="index.html@p=4610.html#"
                            >
                              <span className="elementor-button-content-wrapper">
                                <span className="elementor-button-text">
                                  BOOK YOUR TRANSMISSION
                                </span>
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          {/* #page */}
        </div>
        {/* #page */}
      </>
  </div>
  );
};

export default UsedTransmissions;