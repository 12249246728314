import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
 
// Importing CSS files
import './assets/files/plugins/formidable/css/formidableforms.css';
import './assets/files/plugins/header-footer-elementor/inc/widgets-css/frontend.css';
import './assets/files/plugins/contact-form-7/includes/css/styles.css';
import './assets/files/plugins/header-footer-elementor/assets/css/header-footer-elementor.css';
import './assets/files/plugins/elementor/assets/css/frontend-lite.min.css';
import './assets/files/plugins/elementor/assets/lib/swiper/v8/css/swiper.min.css';
import './assets/files/uploads/elementor/css/post-5040.css';
import './assets/files/uploads/elementor/css/post-4877.css';
import './assets/files/plugins/happy-elementor-addons/assets/fonts/style.min.css';
import './assets/files/plugins/elementor/assets/lib/font-awesome/css/font-awesome.min.css';
import './assets/files/plugins/partdo-core/woocommerce-filter/single-ajax/css/single-ajax.css';
import './assets/files/plugins/partdo-core/woocommerce-filter/notice-ajax/css/notice-ajax.css';
import './assets/files/plugins/partdo-core/woocommerce-filter/swatches/css/swatches.css';
import './assets/files/plugins/partdo-core/woocommerce-filter/shipping-progress-bar/css/free-shipping.css';
import './assets/files/plugins/partdo-core/woocommerce-filter/back-to-top/css/back-to-top.css';
import './assets/files/plugins/partdo-core/woocommerce-filter/ajax-search/css/ajax-search.css';
import './assets/files/plugins/woo-smart-compare/assets/libs/hint/hint.min.css';
import './assets/files/plugins/woo-smart-compare/assets/libs/perfect-scrollbar/css/perfect-scrollbar.min.css';
import './assets/files/plugins/woo-smart-compare/assets/libs/perfect-scrollbar/css/custom-theme.css';
import './assets/files/plugins/woo-smart-compare/assets/css/frontend.css';
import './assets/files/plugins/ti-woocommerce-wishlist/assets/fonts/tinvwl-webfont.woff2';
import './assets/files/plugins/ti-woocommerce-wishlist/assets/css/webfont.min.css';
import './assets/files/plugins/ti-woocommerce-wishlist/assets/css/public.min.css';
import './assets/files/themes/partdo/assets/css/bootstrap.min.css';
import './assets/files/themes/partdo-child/style.css';
import './assets/files/themes/partdo/assets/css/base.css';
import './assets/files/themes/partdo/style.css';
import './assets/files/uploads/happyaddons/css/ha-5040.css';
import './assets/css/brands.css';
import './assets/css/cuatom-style.css';




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);