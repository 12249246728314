import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { AuthProvider } from './context/AuthContext';
import Layout from './components/Layout';

// Frontend Pages
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import ThankYouPage from './pages/Thankyou';

// Categories Pages
import UsedEngines from './pages/categories/UsedEngines';
import UsedTransmissions from './pages/categories/UsedTransmissions';
import UsedAutoParts from './pages/categories/UsedAutoParts';
import RebuiltEngines from './pages/categories/RebuiltEngines';
import RebuiltTransmissions from './pages/categories/RebuiltTransmissions';

// Policies Pages
import PrivacyPolicy from './pages/policies/PrivacyPolicy';
import WarrantyPolicy from './pages/policies/WarrantyPolicy';
import ReturnPolicy from './pages/policies/ReturnPolicy';

// Auth Pages
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import StaffLogin from './pages/auth/StaffLogin';
import StaffRegister from './pages/auth/StaffRegister';

// Dashboard Pages
import Dashboard from './pages/dashboard/Dashboard';
import Requests from './pages/dashboard/Requests';
import Parts from './pages/dashboard/Parts';
import Leads from './pages/dashboard/Leads';


function App() {

  useEffect(() => {
      const links = [
          "https://code.jquery.com/jquery-3.6.0.min.js",
          "https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/owl.carousel.min.js",
          "https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" 
      ];

      // Load scripts dynamically
      links.forEach(link => {
          const scriptElement = document.createElement('script');
          scriptElement.src = link;
          scriptElement.async = true; // Load asynchronously
          scriptElement.onload = () => {
              // Initialize Owl Carousel after jQuery and Owl Carousel are loaded
              if (link.includes('owl.carousel.min.js')) {
                  // Ensure jQuery is available
                  if (window.jQuery) {
                      window.jQuery('.owl-carousel').owlCarousel({
                          loop: true,
                          autoplay: true,
                          autoplayTimeout: 2000,
                          smartSpeed: 1000,
                          autoplayHoverPause: true,
                          items: 1,
                          dots: false,
                          nav: true,
                          navText: ["<", ">"],
                      });
                  }
              }
          };
          document.body.appendChild(scriptElement);
      });

      // Cleanup function to remove the scripts if needed
      return () => {
          links.forEach(link => {
              const scriptElement = document.querySelector(`script[src="${link}"]`);
              if (scriptElement) {
                  document.body.removeChild(scriptElement);
              }
          });
      };
  }, []);

  useEffect(() => {
      const links = [
          "https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600,700,800,900&display=swap",
          "https://fonts.googleapis.com/css2?family=Krub:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&subset=latin,latin-ext",
          "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.7.2/css/all.min.css",
          "https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.carousel.min.css",
          "https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.theme.default.min.css",
          "https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css"
      ];

      links.forEach(link => {
          const linkElement = document.createElement('link');
          linkElement.rel = 'stylesheet';
          linkElement.href = link;
          document.head.appendChild(linkElement);
      });

      // Cleanup function to remove the stylesheets if needed
      return () => {
          links.forEach(link => {
              const linkElement = document.querySelector(`link[href="${link}"]`);
              if (linkElement) {
                  document.head.removeChild(linkElement);
              }
          });
      };
  }, []);

  return (
    <Router>
      <AuthProvider>
        <Toaster position="top-right" />
        <Routes>
          <Route element={<Layout />}>
            {/* frontend */}
            <Route path="/" element={<Home/>} />
            <Route path="/home" element={<Home/>} />
            <Route path="/about" element={<About/>} />
            <Route path="/contact" element={<Contact/>} />
            <Route path="/thank-you" element={<ThankYouPage/>} />

            {/* categories */}
            <Route path="/categories/used-engines" element={<UsedEngines/>} />
            <Route path="/categories/used-transmissions" element={<UsedTransmissions/>} />
            <Route path="/categories/used-auto-parts" element={<UsedAutoParts/>} />
            <Route path="/categories/rebuilt-engines" element={<RebuiltEngines/>} />
            <Route path="/categories/rebuilt-transmissions" element={<RebuiltTransmissions/>} />

            {/* policies */}
            <Route path="/policies/privacy-policy" element={<PrivacyPolicy/>} />
            <Route path="/policies/warranty-policy" element={<WarrantyPolicy/>} />
            <Route path="/policies/return-policy" element={<ReturnPolicy/>} />

            {/* auth */}
            <Route path="/login" element={<Login/>} />
            <Route path="/register" element={<Register/>} />
            <Route path="/staff/login" element={<StaffLogin/>} />
            <Route path="/staff/register" element={<StaffRegister/>} />
            <Route element={<Layout />}>
              <Route path="/dashboard" element={<Dashboard/>} />
              <Route path="/requests" element={<Requests/>} />
              <Route path="/parts" element={<Parts/>} />
              <Route path="/leads" element={<Leads/>} />
            </Route>

            <Route path="*" element={<Navigate to="/" replace />} />
          </Route>
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;



