import React from 'react';

const UsedAutoParts = () => {
  return (
    <div>
      <>
        <div className="site-primary" id="main">
          <div className="site-content" id="content">
            <div
              data-elementor-type="wp-page"
              data-elementor-id={4657}
              className="elementor elementor-4657"
            >
              <section
                className="elementor-section elementor-top-section elementor-element elementor-element-a0e8e86 elementor-section-height-min-height elementor-section-boxed elementor-section-height-default elementor-section-items-middle"
                data-id="a0e8e86"
                data-element_type="section"
                data-settings='{"background_background":"classic","_ha_eqh_enable":false}'
              >
                <div className="elementor-background-overlay" />
                <div className="elementor-container elementor-column-gap-default">
                  <div
                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-813ad9f"
                    data-id="813ad9f"
                    data-element_type="column"
                  >
                    <div className="elementor-widget-wrap elementor-element-populated">
                      <div
                        className="elementor-element elementor-element-1cd7907 elementor-widget elementor-widget-heading"
                        data-id="1cd7907"
                        data-element_type="widget"
                        data-widget_type="heading.default"
                      >
                        <div className="elementor-widget-container">
                          <style
                            dangerouslySetInnerHTML={{
                              __html:
                                "\n\t\t\t\t\t\t\t\t\t\t\t\t/*! elementor - v3.22.0 - 26-06-2024 */\n\t\t\t\t\t\t\t\t\t\t\t\t.elementor-heading-title {\n\t\t\t\t\t\t\t\t\t\t\t\t\tpadding: 0;\n\t\t\t\t\t\t\t\t\t\t\t\t\tmargin: 0;\n\t\t\t\t\t\t\t\t\t\t\t\t\tline-height: 1\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-heading .elementor-heading-title[class*=elementor-size-]>a {\n\t\t\t\t\t\t\t\t\t\t\t\t\tcolor: inherit;\n\t\t\t\t\t\t\t\t\t\t\t\t\tfont-size: inherit;\n\t\t\t\t\t\t\t\t\t\t\t\t\tline-height: inherit\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-heading .elementor-heading-title.elementor-size-small {\n\t\t\t\t\t\t\t\t\t\t\t\t\tfont-size: 15px\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-heading .elementor-heading-title.elementor-size-medium {\n\t\t\t\t\t\t\t\t\t\t\t\t\tfont-size: 19px\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-heading .elementor-heading-title.elementor-size-large {\n\t\t\t\t\t\t\t\t\t\t\t\t\tfont-size: 29px\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-heading .elementor-heading-title.elementor-size-xl {\n\t\t\t\t\t\t\t\t\t\t\t\t\tfont-size: 39px\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-heading .elementor-heading-title.elementor-size-xxl {\n\t\t\t\t\t\t\t\t\t\t\t\t\tfont-size: 59px\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t\t\t"
                            }}
                          />
                          <h2 className="elementor-heading-title elementor-size-default">
                            Used Auto Part
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section
                className="elementor-section elementor-top-section elementor-element elementor-element-93b8d50 elementor-section-height-min-height elementor-section-boxed elementor-section-height-default elementor-section-items-middle"
                data-id="93b8d50"
                data-element_type="section"
                data-settings='{"_ha_eqh_enable":false}'
              >
                <div className="elementor-container elementor-column-gap-default">
                  <div
                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ed9bdee"
                    data-id="ed9bdee"
                    data-element_type="column"
                  >
                    <div className="elementor-widget-wrap elementor-element-populated">
                      <section
                        className="elementor-section elementor-inner-section elementor-element elementor-element-7ca0b40 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                        data-id="7ca0b40"
                        data-element_type="section"
                        data-settings='{"_ha_eqh_enable":false}'
                      >
                        <div className="elementor-container elementor-column-gap-default">
                          <div
                            className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-65c4a5e"
                            data-id="65c4a5e"
                            data-element_type="column"
                          >
                            <div className="elementor-widget-wrap elementor-element-populated">
                              <div
                                className="elementor-element elementor-element-b40e5f5 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                                data-id="b40e5f5"
                                data-element_type="widget"
                                data-widget_type="icon-list.default"
                              >
                                <div className="elementor-widget-container">
                                  <link
                                    rel="stylesheet"
                                    href="../assets/files/plugins/elementor/assets/css/widget-icon-list.min.css"
                                  />
                                  <ul className="elementor-icon-list-items">
                                    <li className="elementor-icon-list-item">
                                      <a href="/used-engines">
                                        <span className="elementor-icon-list-icon">
                                          <svg
                                            aria-hidden="true"
                                            className="e-font-icon-svg e-fas-dot-circle"
                                            viewBox="0 0 512 512"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm80 248c0 44.112-35.888 80-80 80s-80-35.888-80-80 35.888-80 80-80 80 35.888 80 80z"></path>
                                          </svg>{" "}
                                        </span>
                                        <span className="elementor-icon-list-text">
                                          Used Engines
                                        </span>
                                      </a>
                                    </li>
                                    <li className="elementor-icon-list-item">
                                      <a href="/used-transmissions">
                                        <span className="elementor-icon-list-icon">
                                          <svg
                                            aria-hidden="true"
                                            className="e-font-icon-svg e-fas-dot-circle"
                                            viewBox="0 0 512 512"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm80 248c0 44.112-35.888 80-80 80s-80-35.888-80-80 35.888-80 80-80 80 35.888 80 80z"></path>
                                          </svg>{" "}
                                        </span>
                                        <span className="elementor-icon-list-text">
                                          Used Transmissions
                                        </span>
                                      </a>
                                    </li>
                                    <li className="elementor-icon-list-item">
                                      <a href="/used-auto-spare-parts">
                                        <span className="elementor-icon-list-icon">
                                          <svg
                                            aria-hidden="true"
                                            className="e-font-icon-svg e-fas-dot-circle"
                                            viewBox="0 0 512 512"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm80 248c0 44.112-35.888 80-80 80s-80-35.888-80-80 35.888-80 80-80 80 35.888 80 80z"></path>
                                          </svg>{" "}
                                        </span>
                                        <span className="elementor-icon-list-text">
                                          Used Auto Parts
                                        </span>
                                      </a>
                                    </li>
                                    <li className="elementor-icon-list-item">
                                      <a href="/rebuilt-engines">
                                        <span className="elementor-icon-list-icon">
                                          <svg
                                            aria-hidden="true"
                                            className="e-font-icon-svg e-fas-dot-circle"
                                            viewBox="0 0 512 512"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm80 248c0 44.112-35.888 80-80 80s-80-35.888-80-80 35.888-80 80-80 80 35.888 80 80z"></path>
                                          </svg>{" "}
                                        </span>
                                        <span className="elementor-icon-list-text">
                                          Rebuilt Engines
                                        </span>
                                      </a>
                                    </li>
                                    <li className="elementor-icon-list-item">
                                      <a href="/rebuilt-transmissions">
                                        <span className="elementor-icon-list-icon">
                                          <svg
                                            aria-hidden="true"
                                            className="e-font-icon-svg e-fas-dot-circle"
                                            viewBox="0 0 512 512"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm80 248c0 44.112-35.888 80-80 80s-80-35.888-80-80 35.888-80 80-80 80 35.888 80 80z"></path>
                                          </svg>{" "}
                                        </span>
                                        <span className="elementor-icon-list-text">
                                          Rebuilt Transmissions
                                        </span>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-bd2dc2d elementor-widget elementor-widget-image"
                                data-id="bd2dc2d"
                                data-element_type="widget"
                                data-widget_type="image.default"
                              >
                                <div className="elementor-widget-container">
                                  <style
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        '\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t/*! elementor - v3.22.0 - 26-06-2024 */\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-image {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\ttext-align: center\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-image a {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tdisplay: inline-block\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-image a img[src$=".svg"] {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\twidth: 48px\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-image img {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tvertical-align: middle;\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tdisplay: inline-block\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t'
                                    }}
                                  />{" "}
                                  <img
                                    decoding="async"
                                    width={600}
                                    height={427}
                                    src="../assets/files/uploads/2024/02/p-parts.png"
                                    className="attachment-large size-large wp-image-4313"
                                    alt=""
                                    srcSet="../assets/files/uploads/2024/02/p-parts.png 600w, ../assets/files/uploads/2024/02/p-parts-76x54.png 76w, ../assets/files/uploads/2024/02/p-parts-300x214.png 300w"
                                    sizes="(max-width: 600px) 100vw, 600px"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-5636bcb"
                            data-id="5636bcb"
                            data-element_type="column"
                          >
                            <div className="elementor-widget-wrap elementor-element-populated">
                              <div
                                className="elementor-element elementor-element-b21496b elementor-widget elementor-widget-heading"
                                data-id="b21496b"
                                data-element_type="widget"
                                data-widget_type="heading.default"
                              >
                                <div className="elementor-widget-container">
                                  <h2 className="elementor-heading-title elementor-size-default">
                                    Used Auto Spare Parts
                                  </h2>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-c3663e4 elementor-widget elementor-widget-text-editor"
                                data-id="c3663e4"
                                data-element_type="widget"
                                data-widget_type="text-editor.default"
                              >
                                <div className="elementor-widget-container">
                                  <style
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t/*! elementor - v3.22.0 - 26-06-2024 */\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-text-editor.elementor-drop-cap-view-stacked .elementor-drop-cap {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tbackground-color: #69727d;\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tcolor: #fff\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-text-editor.elementor-drop-cap-view-framed .elementor-drop-cap {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tcolor: #69727d;\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tborder: 3px solid;\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tbackground-color: transparent\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tmargin-top: 8px\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap-letter {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\twidth: 1em;\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\theight: 1em\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-text-editor .elementor-drop-cap {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tfloat: left;\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\ttext-align: center;\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tline-height: 1;\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tfont-size: 50px\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-text-editor .elementor-drop-cap-letter {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tdisplay: inline-block\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                    }}
                                  />
                                  <p>
                                    Welcome to Motor Tiger Inc., your trusted source
                                    for reliable and affordable used auto spare parts.
                                    Explore our extensive inventory, where quality
                                    meets affordability, providing you with the
                                    perfect solutions for your vehicle needs.
                                  </p>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-96a2f1c elementor-widget elementor-widget-heading"
                                data-id="96a2f1c"
                                data-element_type="widget"
                                data-widget_type="heading.default"
                              >
                                <div className="elementor-widget-container">
                                  <h2 className="elementor-heading-title elementor-size-default">
                                    Discover a Wide Range
                                  </h2>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-d6dced0 elementor-widget elementor-widget-text-editor"
                                data-id="d6dced0"
                                data-element_type="widget"
                                data-widget_type="text-editor.default"
                              >
                                <div className="elementor-widget-container">
                                  <p>
                                    Uncover a wide range of reliable and affordable
                                    used auto spare parts suitable for various makes
                                    and models. Whatever your vehicle requires, we
                                    have an extensive selection that caters to your
                                    specific needs.
                                  </p>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-6b82b85 elementor-widget elementor-widget-heading"
                                data-id="6b82b85"
                                data-element_type="widget"
                                data-widget_type="heading.default"
                              >
                                <div className="elementor-widget-container">
                                  <h2 className="elementor-heading-title elementor-size-default">
                                    Extensive Inventory, High Quality
                                  </h2>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-25be693 elementor-widget elementor-widget-text-editor"
                                data-id="25be693"
                                data-element_type="widget"
                                data-widget_type="text-editor.default"
                              >
                                <div className="elementor-widget-container">
                                  <p>
                                    Browse through our extensive inventory of
                                    high-quality used auto spare parts. We understand
                                    the importance of finding the perfect fit for your
                                    vehicle, and our diverse range ensures that you
                                    have options to choose from without compromising
                                    on quality.
                                  </p>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-e08d815 elementor-widget elementor-widget-heading"
                                data-id="e08d815"
                                data-element_type="widget"
                                data-widget_type="heading.default"
                              >
                                <div className="elementor-widget-container">
                                  <h2 className="elementor-heading-title elementor-size-default">
                                    Save Money without Compromising
                                  </h2>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-e2139c4 elementor-widget elementor-widget-text-editor"
                                data-id="e2139c4"
                                data-element_type="widget"
                                data-widget_type="text-editor.default"
                              >
                                <div className="elementor-widget-container">
                                  Choose from our selection of carefully inspected
                                  used auto parts and save money without compromising
                                  on quality. We prioritize thorough inspections to
                                  meet our strict quality standards, providing you
                                  with reliable components at an affordable price.{" "}
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-b689176 elementor-widget elementor-widget-heading"
                                data-id="b689176"
                                data-element_type="widget"
                                data-widget_type="heading.default"
                              >
                                <div className="elementor-widget-container">
                                  <h2 className="elementor-heading-title elementor-size-default">
                                    Cost-Effective Solutions
                                  </h2>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-950c8f8 elementor-widget elementor-widget-text-editor"
                                data-id="950c8f8"
                                data-element_type="widget"
                                data-widget_type="text-editor.default"
                              >
                                <div className="elementor-widget-container">
                                  Whether you’re in need of a replacement part or an
                                  upgrade, our selection of used auto parts offers a
                                  cost-effective solution. Trust in the durability and
                                  functionality of our parts, carefully sourced and
                                  tested for optimal performance.{" "}
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-29d33e1 elementor-widget elementor-widget-heading"
                                data-id="29d33e1"
                                data-element_type="widget"
                                data-widget_type="heading.default"
                              >
                                <div className="elementor-widget-container">
                                  <h2 className="elementor-heading-title elementor-size-default">
                                    Expertly Sourced and Tested
                                  </h2>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-0a18ad6 elementor-widget elementor-widget-text-editor"
                                data-id="0a18ad6"
                                data-element_type="widget"
                                data-widget_type="text-editor.default"
                              >
                                <div className="elementor-widget-container">
                                  Our knowledgeable team takes pride in offering a
                                  diverse range of used auto parts in excellent
                                  condition. Each component has been expertly sourced
                                  and tested, ready to be installed in your vehicle,
                                  ensuring reliability and longevity.{" "}
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-b562c52 elementor-widget elementor-widget-heading"
                                data-id="b562c52"
                                data-element_type="widget"
                                data-widget_type="heading.default"
                              >
                                <div className="elementor-widget-container">
                                  <h2 className="elementor-heading-title elementor-size-default">
                                    Find the Specific Component You Need
                                  </h2>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-80579e7 elementor-widget elementor-widget-text-editor"
                                data-id="80579e7"
                                data-element_type="widget"
                                data-widget_type="text-editor.default"
                              >
                                <div className="elementor-widget-container">
                                  <p>
                                    With our wide selection of used auto spare parts,
                                    finding the specific component your vehicle
                                    requires has never been easier. We strive to
                                    provide you with the parts you need to keep your
                                    vehicle running smoothly.
                                  </p>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-9ea8f1e elementor-widget elementor-widget-heading"
                                data-id="9ea8f1e"
                                data-element_type="widget"
                                data-widget_type="heading.default"
                              >
                                <div className="elementor-widget-container">
                                  <h2 className="elementor-heading-title elementor-size-default">
                                    Hassle-Free Experience
                                  </h2>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-1177edd elementor-widget elementor-widget-text-editor"
                                data-id="1177edd"
                                data-element_type="widget"
                                data-widget_type="text-editor.default"
                              >
                                <div className="elementor-widget-container">
                                  <p>
                                    Our experienced team is here to assist you in
                                    finding the right used auto spare parts for your
                                    vehicle, ensuring a hassle-free experience. Trust
                                    our expertise to guide you through the process and
                                    meet your unique requirements.
                                  </p>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-8029aa2 elementor-widget elementor-widget-heading"
                                data-id="8029aa2"
                                data-element_type="widget"
                                data-widget_type="heading.default"
                              >
                                <div className="elementor-widget-container">
                                  <h2 className="elementor-heading-title elementor-size-default">
                                    Convenient and Affordable
                                  </h2>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-91ac2f1 elementor-widget elementor-widget-text-editor"
                                data-id="91ac2f1"
                                data-element_type="widget"
                                data-widget_type="text-editor.default"
                              >
                                <div className="elementor-widget-container">
                                  <p>
                                    Experience the convenience and affordability of
                                    our used auto spare parts, backed by our
                                    commitment to customer satisfaction. We are
                                    dedicated to providing reliable solutions that
                                    align with your budget and vehicle needs.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </section>
              <section
                className="elementor-section elementor-top-section elementor-element elementor-element-077b72c elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                data-id="077b72c"
                data-element_type="section"
                data-settings='{"background_background":"classic","_ha_eqh_enable":false}'
              >
                <div className="elementor-background-overlay" />
                <div className="elementor-container elementor-column-gap-default">
                  <div
                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-3ec8eb0"
                    data-id="3ec8eb0"
                    data-element_type="column"
                  >
                    <div className="elementor-widget-wrap elementor-element-populated">
                      <div
                        className="elementor-element elementor-element-62461cb elementor-align-center elementor-widget__width-auto elementor-widget elementor-widget-button"
                        data-id="62461cb"
                        data-element_type="widget"
                        data-widget_type="button.default"
                      >
                        <div className="elementor-widget-container">
                          <div className="elementor-button-wrapper">
                            <a
                              className="elementor-button elementor-button-link elementor-size-sm"
                              href="/used-auto-spare-parts#"
                            >
                              <span className="elementor-button-content-wrapper">
                                <span className="elementor-button-text">
                                  CALL US NOW
                                </span>
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div
                        className="elementor-element elementor-element-e262a17 elementor-align-center elementor-widget__width-auto elementor-widget elementor-widget-button"
                        data-id="e262a17"
                        data-element_type="widget"
                        data-widget_type="button.default"
                      >
                        <div className="elementor-widget-container">
                          <div className="elementor-button-wrapper">
                            <a
                              className="elementor-button elementor-button-link elementor-size-sm"
                              href="/used-auto-spare-parts#"
                            >
                              <span className="elementor-button-content-wrapper">
                                <span className="elementor-button-text">
                                  BOOK YOUR TRANSMISSION
                                </span>
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          {/* #page */}
        </div>
        {/* #page */}
      </>
  </div>
  );
};

export default UsedAutoParts;