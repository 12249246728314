import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


const Contact = () => {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
      e.preventDefault();

      try {
          const formData = new FormData();
          formData.append('your-name', name);
          formData.append('your-email', email);
          formData.append('your-subject', subject);
          formData.append('your-message', message);

          const response = await fetch('https://staging.motortiger.com/super-files/contact-email.php', {
              method: 'POST',
              body: formData,
          });

          if (response.ok) {
              navigate('/thank-you');
          } else {
              console.error('Error sending message');
          }
      } catch (error) {
          console.error('Error sending message:', error);
      }
  };

  return (
    <div>
        <div className="site-primary" id="main">
          <div className="site-content" id="content">
            <div
              data-elementor-type="wp-page"
              data-elementor-id={2144}
              className="elementor elementor-2144"
            >
              <section
                className="elementor-section elementor-top-section elementor-element elementor-element-ecb62ad elementor-section-height-min-height elementor-section-boxed elementor-section-height-default elementor-section-items-middle"
                data-id="ecb62ad"
                data-element_type="section"
                data-settings='{"background_background":"classic","_ha_eqh_enable":false}'
              >
                <div className="elementor-container elementor-column-gap-extended">
                  <div
                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-d8c99b1"
                    data-id="d8c99b1"
                    data-element_type="column"
                  >
                    <div className="elementor-widget-wrap elementor-element-populated">
                      <div
                        className="elementor-element elementor-element-e9412da elementor-widget elementor-widget-heading"
                        data-id="e9412da"
                        data-element_type="widget"
                        data-widget_type="heading.default"
                      >
                        <div className="elementor-widget-container">
                          <style
                            dangerouslySetInnerHTML={{
                              __html:
                                "\n\t\t\t\t\t\t\t\t\t\t\t\t/*! elementor - v3.22.0 - 26-06-2024 */\n\t\t\t\t\t\t\t\t\t\t\t\t.elementor-heading-title {\n\t\t\t\t\t\t\t\t\t\t\t\t\tpadding: 0;\n\t\t\t\t\t\t\t\t\t\t\t\t\tmargin: 0;\n\t\t\t\t\t\t\t\t\t\t\t\t\tline-height: 1\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-heading .elementor-heading-title[class*=elementor-size-]>a {\n\t\t\t\t\t\t\t\t\t\t\t\t\tcolor: inherit;\n\t\t\t\t\t\t\t\t\t\t\t\t\tfont-size: inherit;\n\t\t\t\t\t\t\t\t\t\t\t\t\tline-height: inherit\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-heading .elementor-heading-title.elementor-size-small {\n\t\t\t\t\t\t\t\t\t\t\t\t\tfont-size: 15px\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-heading .elementor-heading-title.elementor-size-medium {\n\t\t\t\t\t\t\t\t\t\t\t\t\tfont-size: 19px\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-heading .elementor-heading-title.elementor-size-large {\n\t\t\t\t\t\t\t\t\t\t\t\t\tfont-size: 29px\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-heading .elementor-heading-title.elementor-size-xl {\n\t\t\t\t\t\t\t\t\t\t\t\t\tfont-size: 39px\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-heading .elementor-heading-title.elementor-size-xxl {\n\t\t\t\t\t\t\t\t\t\t\t\t\tfont-size: 59px\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t\t\t"
                            }}
                          />
                          <h2 className="elementor-heading-title elementor-size-default">
                            Contact Us
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section
                className="elementor-section elementor-top-section elementor-element elementor-element-c985278 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                data-id="c985278"
                data-element_type="section"
                data-settings='{"_ha_eqh_enable":false}'
              >
                <div className="elementor-container elementor-column-gap-extended">
                  <div
                    className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-31bd2c9"
                    data-id="31bd2c9"
                    data-element_type="column"
                  >
                    <div className="elementor-widget-wrap elementor-element-populated">
                      <div
                        className="elementor-element elementor-element-1196440 elementor-widget elementor-widget-heading"
                        data-id={1196440}
                        data-element_type="widget"
                        data-widget_type="heading.default"
                      >
                        <div className="elementor-widget-container">
                          <h2 className="elementor-heading-title elementor-size-default">
                            How Can We Help You?
                          </h2>
                        </div>
                      </div>
                      <section
                        className="elementor-section elementor-inner-section elementor-element elementor-element-5a5318d elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                        data-id="5a5318d"
                        data-element_type="section"
                        data-settings='{"_ha_eqh_enable":false}'
                      >
                        <div className="elementor-container elementor-column-gap-no">
                          <div
                            className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-eac8ea6"
                            data-id="eac8ea6"
                            data-element_type="column"
                          >
                            <div className="elementor-widget-wrap elementor-element-populated">
                              <div
                                className="elementor-element elementor-element-b8848d7 elementor-widget elementor-widget-partdo-address-box"
                                data-id="b8848d7"
                                data-element_type="widget"
                                data-widget_type="partdo-address-box.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="contact-addresses">
                                    <div className="address-item">
                                      {" "}
                                      <span>Address</span>
                                      <div className="address-detail">
                                        {" "}
                                        6000 Big Pine Dr, Fontana, CA 92336, United
                                        States
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <div
                        className="elementor-element elementor-element-2b585e2 elementor-widget elementor-widget-partdo-contact-icon-box"
                        data-id="2b585e2"
                        data-element_type="widget"
                        data-widget_type="partdo-contact-icon-box.default"
                      >
                        <div className="elementor-widget-container">
                          <div className="contact-iconboxes">
                            <div className="iconbox">
                              <div className="icon">
                                {" "}
                                <i className="klbth-icon-phone-squared" />
                              </div>
                              <div className="detail">
                                {" "}
                                <span>Phone Number</span>
                                <p>
                                  <a
                                    style={{ fontSize: 20 }}
                                    href="tel:+1(855) 253-1635"
                                  >
                                    <span className="elementor-icon-list-text">
                                      1 (855) 253-1635
                                    </span>
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="elementor-element elementor-element-3288e6c elementor-widget elementor-widget-partdo-contact-icon-box"
                        data-id="3288e6c"
                        data-element_type="widget"
                        data-widget_type="partdo-contact-icon-box.default"
                      >
                        <div className="elementor-widget-container">
                          <div className="contact-iconboxes">
                            <div className="iconbox">
                              <div className="icon">
                                {" "}
                                <i className="klbth-icon-phone-squared" />
                              </div>
                              <div className="detail">
                                {" "}
                                <span>Phone Number</span>
                                <p>
                                  <a
                                    style={{ fontSize: 20 }}
                                    href="tel:+1(877)-473-7675"
                                  >
                                    <span className="elementor-icon-list-text">
                                      +1 (877)-473-7675
                                    </span>
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="elementor-element elementor-element-7f3349a elementor-widget elementor-widget-partdo-contact-icon-box"
                        data-id="7f3349a"
                        data-element_type="widget"
                        data-widget_type="partdo-contact-icon-box.default"
                      >
                        <div className="elementor-widget-container">
                          <div className="contact-iconboxes">
                            <div className="iconbox">
                              <div className="icon">
                                {" "}
                                <i className="klbth-icon-envelope-filled" />
                              </div>
                              <div className="detail">
                                {" "}
                                <span>Email Address</span>
                                <p></p>
                                <p>
                                  <a
                                    style={{ fontSize: 20 }}
                                    href="mailto:info@motortiger.com"
                                  >
                                    info@motortiger.com
                                  </a>
                                </p>
                                <p />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="elementor-element elementor-element-a6b9550 elementor-widget elementor-widget-heading"
                        data-id="a6b9550"
                        data-element_type="widget"
                        data-widget_type="heading.default"
                      >
                        <div className="elementor-widget-container">
                          <span className="elementor-heading-title elementor-size-default">
                            Follow us on social media:
                          </span>
                        </div>
                      </div>
                      <div
                        className="elementor-element elementor-element-2878430 elementor-shape-circle elementor-grid-5 e-grid-align-left elementor-widget elementor-widget-social-icons"
                        data-id={2878430}
                        data-element_type="widget"
                        data-widget_type="social-icons.default"
                      >
                        <div className="elementor-widget-container">

                          <div className="elementor-social-icons-wrapper elementor-grid">
                            <span className="elementor-grid-item">
                              <a
                                className="elementor-icon elementor-social-icon elementor-social-icon-facebook elementor-repeater-item-3c979b1"
                                target="_blank"
                              >
                                <span className="elementor-screen-only">Facebook</span>
                                <svg
                                  className="e-font-icon-svg e-fab-facebook"
                                  viewBox="0 0 512 512"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"></path>
                                </svg>{" "}
                              </a>
                            </span>
                            <span className="elementor-grid-item">
                              <a
                                className="elementor-icon elementor-social-icon elementor-social-icon-twitter elementor-repeater-item-26ce326"
                                target="_blank"
                              >
                                <span className="elementor-screen-only">Twitter</span>
                                <svg
                                  className="e-font-icon-svg e-fab-twitter"
                                  viewBox="0 0 512 512"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path>
                                </svg>{" "}
                              </a>
                            </span>
                            <span className="elementor-grid-item">
                              <a
                                className="elementor-icon elementor-social-icon elementor-social-icon-youtube elementor-repeater-item-faa1c1c"
                                target="_blank"
                              >
                                <span className="elementor-screen-only">Youtube</span>
                                <svg
                                  className="e-font-icon-svg e-fab-youtube"
                                  viewBox="0 0 576 512"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"></path>
                                </svg>{" "}
                              </a>
                            </span>
                            <span className="elementor-grid-item">
                              <a
                                className="elementor-icon elementor-social-icon elementor-social-icon-pinterest elementor-repeater-item-db7c1ae"
                                target="_blank"
                              >
                                <span className="elementor-screen-only">Pinterest</span>
                                <svg
                                  className="e-font-icon-svg e-fab-pinterest"
                                  viewBox="0 0 496 512"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M496 256c0 137-111 248-248 248-25.6 0-50.2-3.9-73.4-11.1 10.1-16.5 25.2-43.5 30.8-65 3-11.6 15.4-59 15.4-59 8.1 15.4 31.7 28.5 56.8 28.5 74.8 0 128.7-68.8 128.7-154.3 0-81.9-66.9-143.2-152.9-143.2-107 0-163.9 71.8-163.9 150.1 0 36.4 19.4 81.7 50.3 96.1 4.7 2.2 7.2 1.2 8.3-3.3.8-3.4 5-20.3 6.9-28.1.6-2.5.3-4.7-1.7-7.1-10.1-12.5-18.3-35.3-18.3-56.6 0-54.7 41.4-107.6 112-107.6 60.9 0 103.6 41.5 103.6 100.9 0 67.1-33.9 113.6-78 113.6-24.3 0-42.6-20.1-36.7-44.8 7-29.5 20.5-61.3 20.5-82.6 0-19-10.2-34.9-31.4-34.9-24.9 0-44.9 25.7-44.9 60.2 0 22 7.4 36.8 7.4 36.8s-24.5 103.8-29 123.2c-5 21.4-3 51.6-.9 71.2C65.4 450.9 0 361.1 0 256 0 119 111 8 248 8s248 111 248 248z"></path>
                                </svg>{" "}
                              </a>
                            </span>
                            <span className="elementor-grid-item">
                              <a
                                className="elementor-icon elementor-social-icon elementor-social-icon-linkedin elementor-repeater-item-64af8d1"
                                target="_blank"
                              >
                                <span className="elementor-screen-only">Linkedin</span>
                                <svg
                                  className="e-font-icon-svg e-fab-linkedin"
                                  viewBox="0 0 448 512"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"></path>
                                </svg>{" "}
                              </a>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-d4b1c9c"
                    data-id="d4b1c9c"
                    data-element_type="column"
                  >
                    <div className="elementor-widget-wrap elementor-element-populated">
                      <div
                        className="elementor-element elementor-element-f824e4b elementor-widget elementor-widget-heading"
                        data-id="f824e4b"
                        data-element_type="widget"
                        data-widget_type="heading.default"
                      >
                        <div className="elementor-widget-container">
                          <h2 className="elementor-heading-title elementor-size-default">
                            Get in Touch
                          </h2>
                        </div>
                      </div>
                      <div
                        className="elementor-element elementor-element-5d759af elementor-widget elementor-widget-partdo-contact-form-7"
                        data-id="5d759af"
                        data-element_type="widget"
                        data-widget_type="partdo-contact-form-7.default"
                      >
                        <div className="elementor-widget-container">
                          <div className="contact-form">
                            <div
                              className="wpcf7 no-js"
                              id="wpcf7-f2299-p2144-o1"
                              lang="en-US"
                              dir="ltr"
                            >
                              <div className="screen-reader-response">
                                <p
                                  role="status"
                                  aria-live="polite"
                                  aria-atomic="true"
                                />
                                <ul />
                              </div>
                              <form onSubmit={handleSubmit} className="wpcf7-form init" aria-label="Contact form">
                                  <div className="row">
                                      <div className="col-12 col-lg-6">
                                          <p>
                                              <label>Your name *</label>
                                              <br />
                                              <span className="wpcf7-form-control-wrap" data-name="your-name">
                                                  <input
                                                      size={40}
                                                      maxLength={80}
                                                      className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                      aria-required="true"
                                                      aria-invalid="false"
                                                      type="text"
                                                      name="your-name"
                                                      value={name}
                                                      onChange={(e) => setName(e.target.value)}
                                                      required
                                                  />
                                              </span>
                                          </p>
                                      </div>
                                      <div className="col-12 col-lg-6">
                                          <p>
                                              <label>Your email *</label>
                                              <br />
                                              <span className="wpcf7-form-control-wrap" data-name="your-email">
                                                  <input
                                                      size={40}
                                                      maxLength={80}
                                                      className="wpcf7-form-control wpcf7-email wpcf7-validates-as-required wpcf7-text wpcf7-validates-as-email"
                                                      aria-required="true"
                                                      aria-invalid="false"
                                                      type="email"
                                                      name="your-email"
                                                      value={email}
                                                      onChange={(e) => setEmail(e.target.value)}
                                                      required
                                                  />
                                              </span>
                                          </p>
                                      </div>
                                      <div className="col-12 col-lg-12">
                                          <p>
                                              <label>Subject *</label>
                                              <br />
                                              <span className="wpcf7-form-control-wrap" data-name="your-subject">
                                                  <input
                                                      size={40}
                                                      maxLength={80}
                                                      className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                      aria-required="true"
                                                      aria-invalid="false"
                                                      type="text"
                                                      name="your-subject"
                                                      value={subject}
                                                      onChange={(e) => setSubject(e.target.value)}
                                                      required
                                                  />
                                              </span>
                                          </p>
                                      </div>
                                      <div className="col-12 col-lg-12">
                                          <p>
                                              <label>Your message</label>
                                              <br />
                                              <span className="wpcf7-form-control-wrap" data-name="your-message">
                                                  <textarea
                                                      cols={40}
                                                      rows={10}
                                                      maxLength={400}
                                                      className="wpcf7-form-control wpcf7-textarea"
                                                      aria-invalid="false"
                                                      name="your-message"
                                                      value={message}
                                                      onChange={(e) => setMessage(e.target.value)}
                                                  />
                                              </span>
                                          </p>
                                      </div>
                                      <div className="col-12 col-lg-12">
                                          <p>
                                              <input
                                                  className="wpcf7-form-control wpcf7-submit has-spinner"
                                                  type="submit"
                                                  value="Send Message"
                                              />
                                          </p>
                                      </div>
                                  </div>
                              </form>
                            </div>
                          </div>
                          {/* contact-form */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
    </div>
  );
};

export default Contact;