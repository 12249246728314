import React from 'react';

const UsedEngines = () => {
  return (
    <div>
      <>
        <div className="site-primary" id="main">
          <div className="site-content" id="content">
            <div
              data-elementor-type="wp-page"
              data-elementor-id={4471}
              className="elementor elementor-4471"
            >
              <section
                className="elementor-section elementor-top-section elementor-element elementor-element-384ddbe6 elementor-section-height-min-height elementor-section-boxed elementor-section-height-default elementor-section-items-middle"
                data-id="384ddbe6"
                data-element_type="section"
                data-settings='{"background_background":"classic","_ha_eqh_enable":false}'
              >
                <div className="elementor-background-overlay" />
                <div className="elementor-container elementor-column-gap-default">
                  <div
                    className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-17dcf76d"
                    data-id="17dcf76d"
                    data-element_type="column"
                  >
                    <div className="elementor-widget-wrap elementor-element-populated">
                      <div
                        className="elementor-element elementor-element-32369413 elementor-widget elementor-widget-heading"
                        data-id={32369413}
                        data-element_type="widget"
                        data-widget_type="heading.default"
                      >
                        <div className="elementor-widget-container">
                          <style
                            dangerouslySetInnerHTML={{
                              __html:
                                "\n\t\t\t\t\t\t\t\t\t\t\t\t/*! elementor - v3.22.0 - 26-06-2024 */\n\t\t\t\t\t\t\t\t\t\t\t\t.elementor-heading-title {\n\t\t\t\t\t\t\t\t\t\t\t\t\tpadding: 0;\n\t\t\t\t\t\t\t\t\t\t\t\t\tmargin: 0;\n\t\t\t\t\t\t\t\t\t\t\t\t\tline-height: 1\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-heading .elementor-heading-title[class*=elementor-size-]>a {\n\t\t\t\t\t\t\t\t\t\t\t\t\tcolor: inherit;\n\t\t\t\t\t\t\t\t\t\t\t\t\tfont-size: inherit;\n\t\t\t\t\t\t\t\t\t\t\t\t\tline-height: inherit\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-heading .elementor-heading-title.elementor-size-small {\n\t\t\t\t\t\t\t\t\t\t\t\t\tfont-size: 15px\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-heading .elementor-heading-title.elementor-size-medium {\n\t\t\t\t\t\t\t\t\t\t\t\t\tfont-size: 19px\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-heading .elementor-heading-title.elementor-size-large {\n\t\t\t\t\t\t\t\t\t\t\t\t\tfont-size: 29px\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-heading .elementor-heading-title.elementor-size-xl {\n\t\t\t\t\t\t\t\t\t\t\t\t\tfont-size: 39px\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-heading .elementor-heading-title.elementor-size-xxl {\n\t\t\t\t\t\t\t\t\t\t\t\t\tfont-size: 59px\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t\t\t"
                            }}
                          />
                          <h2 className="elementor-heading-title elementor-size-default">
                            Used Engines
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-254f0f8"
                    data-id="254f0f8"
                    data-element_type="column"
                  >
                    <div className="elementor-widget-wrap"></div>
                  </div>
                </div>
              </section>
              <section
                className="elementor-section elementor-top-section elementor-element elementor-element-07bf547 elementor-section-height-min-height elementor-section-boxed elementor-section-height-default elementor-section-items-middle"
                data-id="07bf547"
                data-element_type="section"
                data-settings='{"_ha_eqh_enable":false}'
              >
                <div className="elementor-container elementor-column-gap-default">
                  <div
                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-13d2660"
                    data-id="13d2660"
                    data-element_type="column"
                  >
                    <div className="elementor-widget-wrap elementor-element-populated">
                      <section
                        className="elementor-section elementor-inner-section elementor-element elementor-element-6e3922f elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                        data-id="6e3922f"
                        data-element_type="section"
                        data-settings='{"_ha_eqh_enable":false}'
                      >
                        <div className="elementor-container elementor-column-gap-default">
                          <div
                            className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-834d77f"
                            data-id="834d77f"
                            data-element_type="column"
                          >
                            <div className="elementor-widget-wrap elementor-element-populated">
                              <div
                                className="elementor-element elementor-element-92d0fef elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                                data-id="92d0fef"
                                data-element_type="widget"
                                data-widget_type="icon-list.default"
                              >
                                <div className="elementor-widget-container">
                                  <link
                                    rel="stylesheet"
                                    href="../assets/files/plugins/elementor/assets/css/widget-icon-list.min.css"
                                  />
                                  <ul className="elementor-icon-list-items">
                                    <li className="elementor-icon-list-item">
                                      <a href="index.html@p=4471.html">
                                        <span className="elementor-icon-list-icon">
                                          <svg
                                            aria-hidden="true"
                                            className="e-font-icon-svg e-fas-dot-circle"
                                            viewBox="0 0 512 512"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm80 248c0 44.112-35.888 80-80 80s-80-35.888-80-80 35.888-80 80-80 80 35.888 80 80z"></path>
                                          </svg>{" "}
                                        </span>
                                        <span className="elementor-icon-list-text">
                                          Used Engines
                                        </span>
                                      </a>
                                    </li>
                                    <li className="elementor-icon-list-item">
                                      <a href="/used-transmissions">
                                        <span className="elementor-icon-list-icon">
                                          <svg
                                            aria-hidden="true"
                                            className="e-font-icon-svg e-fas-dot-circle"
                                            viewBox="0 0 512 512"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm80 248c0 44.112-35.888 80-80 80s-80-35.888-80-80 35.888-80 80-80 80 35.888 80 80z"></path>
                                          </svg>{" "}
                                        </span>
                                        <span className="elementor-icon-list-text">
                                          Used Transmissions
                                        </span>
                                      </a>
                                    </li>
                                    <li className="elementor-icon-list-item">
                                      <a href="/used-auto-spare-parts">
                                        <span className="elementor-icon-list-icon">
                                          <svg
                                            aria-hidden="true"
                                            className="e-font-icon-svg e-fas-dot-circle"
                                            viewBox="0 0 512 512"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm80 248c0 44.112-35.888 80-80 80s-80-35.888-80-80 35.888-80 80-80 80 35.888 80 80z"></path>
                                          </svg>{" "}
                                        </span>
                                        <span className="elementor-icon-list-text">
                                          Used Auto Parts
                                        </span>
                                      </a>
                                    </li>
                                    <li className="elementor-icon-list-item">
                                      <a href="/rebuilt-engines">
                                        <span className="elementor-icon-list-icon">
                                          <svg
                                            aria-hidden="true"
                                            className="e-font-icon-svg e-fas-dot-circle"
                                            viewBox="0 0 512 512"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm80 248c0 44.112-35.888 80-80 80s-80-35.888-80-80 35.888-80 80-80 80 35.888 80 80z"></path>
                                          </svg>{" "}
                                        </span>
                                        <span className="elementor-icon-list-text">
                                          Rebuilt Engines
                                        </span>
                                      </a>
                                    </li>
                                    <li className="elementor-icon-list-item">
                                      <a href="/rebuilt-transmissions">
                                        <span className="elementor-icon-list-icon">
                                          <svg
                                            aria-hidden="true"
                                            className="e-font-icon-svg e-fas-dot-circle"
                                            viewBox="0 0 512 512"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm80 248c0 44.112-35.888 80-80 80s-80-35.888-80-80 35.888-80 80-80 80 35.888 80 80z"></path>
                                          </svg>{" "}
                                        </span>
                                        <span className="elementor-icon-list-text">
                                          Rebuilt Transmissions
                                        </span>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-a7d947d elementor-widget elementor-widget-ha-cf7 happy-addon ha-cf7"
                                data-id="a7d947d"
                                data-element_type="widget"
                                data-widget_type="ha-cf7.default"
                              >
                                <div className="elementor-widget-container">
                                  <div
                                    className="wpcf7 no-js"
                                    id="wpcf7-f2303-p4471-o1"
                                    lang="en-US"
                                    dir="ltr"
                                  >
                                    <div className="screen-reader-response">
                                      <p
                                        role="status"
                                        aria-live="polite"
                                        aria-atomic="true"
                                      />
                                      <ul />
                                    </div>
                                    <form
                                      action="index.html@p=4471.html#wpcf7-f2303-p4471-o1"
                                      method="post"
                                      className="wpcf7-form init ha-cf7-form"
                                      aria-label="Contact form"
                                      data-status="init"
                                    >
                                      <div style={{ display: "none" }}>
                                        <input
                                          type="hidden"
                                          name="_wpcf7"
                                          defaultValue={2303}
                                        />
                                        <input
                                          type="hidden"
                                          name="_wpcf7_version"
                                          defaultValue="5.9.6"
                                        />
                                        <input
                                          type="hidden"
                                          name="_wpcf7_locale"
                                          defaultValue="en_US"
                                        />
                                        <input
                                          type="hidden"
                                          name="_wpcf7_unit_tag"
                                          defaultValue="wpcf7-f2303-p4471-o1"
                                        />
                                        <input
                                          type="hidden"
                                          name="_wpcf7_container_post"
                                          defaultValue={4471}
                                        />
                                        <input
                                          type="hidden"
                                          name="_wpcf7_posted_data_hash"
                                          defaultValue=""
                                        />
                                      </div>
                                      <div
                                        className="wpcf7-response-output"
                                        aria-hidden="true"
                                      />
                                    </form>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-457934a elementor-widget elementor-widget-image"
                                data-id="457934a"
                                data-element_type="widget"
                                data-widget_type="image.default"
                              >
                                <div className="elementor-widget-container">
                                  <style
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        '\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t/*! elementor - v3.22.0 - 26-06-2024 */\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-image {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\ttext-align: center\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-image a {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tdisplay: inline-block\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-image a img[src$=".svg"] {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\twidth: 48px\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-image img {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tvertical-align: middle;\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tdisplay: inline-block\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t'
                                    }}
                                  />{" "}
                                  <img
                                    decoding="async"
                                    width={400}
                                    height={296}
                                    src="../assets/files/uploads/2024/02/300-engine-assembly.png"
                                    className="attachment-large size-large wp-image-4311"
                                    alt=""
                                    srcSet="../assets/files/uploads/2024/02/300-engine-assembly.png 400w, ../assets/files/uploads/2024/02/300-engine-assembly-73x54.png 73w, ../assets/files/uploads/2024/02/300-engine-assembly-300x222.png 300w"
                                    sizes="(max-width: 400px) 100vw, 400px"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-23930be"
                            data-id="23930be"
                            data-element_type="column"
                          >
                            <div className="elementor-widget-wrap elementor-element-populated">
                              <div
                                className="elementor-element elementor-element-d0fffd6 elementor-widget elementor-widget-heading"
                                data-id="d0fffd6"
                                data-element_type="widget"
                                data-widget_type="heading.default"
                              >
                                <div className="elementor-widget-container">
                                  <h2 className="elementor-heading-title elementor-size-default">
                                    Used Engine
                                  </h2>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-f7e32a0 elementor-widget elementor-widget-text-editor"
                                data-id="f7e32a0"
                                data-element_type="widget"
                                data-widget_type="text-editor.default"
                              >
                                <div className="elementor-widget-container">
                                  <style
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t/*! elementor - v3.22.0 - 26-06-2024 */\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-text-editor.elementor-drop-cap-view-stacked .elementor-drop-cap {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tbackground-color: #69727d;\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tcolor: #fff\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-text-editor.elementor-drop-cap-view-framed .elementor-drop-cap {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tcolor: #69727d;\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tborder: 3px solid;\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tbackground-color: transparent\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tmargin-top: 8px\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap-letter {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\twidth: 1em;\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\theight: 1em\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-text-editor .elementor-drop-cap {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tfloat: left;\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\ttext-align: center;\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tline-height: 1;\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tfont-size: 50px\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-text-editor .elementor-drop-cap-letter {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tdisplay: inline-block\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                    }}
                                  />{" "}
                                  Welcome to Motor Tiger Inc., where we invite you to
                                  elevate your driving experience with our premium
                                  selection of high-performance used engines.
                                  Carefully chosen for their reliability and power.
                                  Our engines are your gateway to a thrilling and
                                  enhanced journey on the road.
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-16bd406 elementor-widget elementor-widget-heading"
                                data-id="16bd406"
                                data-element_type="widget"
                                data-widget_type="heading.default"
                              >
                                <div className="elementor-widget-container">
                                  <h2 className="elementor-heading-title elementor-size-default">
                                    Elevate Your Driving Experience
                                  </h2>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-45b1234 elementor-widget elementor-widget-text-editor"
                                data-id="45b1234"
                                data-element_type="widget"
                                data-widget_type="text-editor.default"
                              >
                                <div className="elementor-widget-container">
                                  Discover a remarkable range of high-performance used
                                  engines that have been meticulously selected to
                                  enhance your driving experience. At Motor Tiger
                                  Inc., we take pride in offering engines known for
                                  their exceptional performance and unwavering
                                  reliability.{" "}
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-1d90bc9 elementor-widget elementor-widget-heading"
                                data-id="1d90bc9"
                                data-element_type="widget"
                                data-widget_type="heading.default"
                              >
                                <div className="elementor-widget-container">
                                  <h2 className="elementor-heading-title elementor-size-default">
                                    Handpicked for Power
                                  </h2>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-cff3db2 elementor-widget elementor-widget-text-editor"
                                data-id="cff3db2"
                                data-element_type="widget"
                                data-widget_type="text-editor.default"
                              >
                                <div className="elementor-widget-container">
                                  Experience the thrill of enhanced power and
                                  performance with our handpicked selection of
                                  high-performance used engines. Each engine in our
                                  renowned collection is chosen for its ability to
                                  deliver a smooth and powerful driving experience.
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-4231c72 elementor-widget elementor-widget-heading"
                                data-id="4231c72"
                                data-element_type="widget"
                                data-widget_type="heading.default"
                              >
                                <div className="elementor-widget-container">
                                  <h2 className="elementor-heading-title elementor-size-default">
                                    Reliability at its Core
                                  </h2>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-eacf7cc elementor-widget elementor-widget-text-editor"
                                data-id="eacf7cc"
                                data-element_type="widget"
                                data-widget_type="text-editor.default"
                              >
                                <div className="elementor-widget-container">
                                  Our commitment to excellence extends to a careful
                                  inspection and selection of every used engine in our
                                  inventory. We understand the importance of
                                  reliability in your vehicle, and that’s why we go
                                  the extra mile to ensure each engine meets our
                                  stringent standards.{" "}
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-0ea4854 elementor-widget elementor-widget-heading"
                                data-id="0ea4854"
                                data-element_type="widget"
                                data-widget_type="heading.default"
                              >
                                <div className="elementor-widget-container">
                                  <h2 className="elementor-heading-title elementor-size-default">
                                    Trust Our Expertise
                                  </h2>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-99373af elementor-widget elementor-widget-text-editor"
                                data-id="99373af"
                                data-element_type="widget"
                                data-widget_type="text-editor.default"
                              >
                                <div className="elementor-widget-container">
                                  When it comes to high-performance used engines,
                                  trust our expertise. We take pride in selecting
                                  engines that will take your driving experience to
                                  the next level. Our dedication to quality means you
                                  can rely on us to deliver the power and performance
                                  you desire.{" "}
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-f775dec elementor-widget elementor-widget-heading"
                                data-id="f775dec"
                                data-element_type="widget"
                                data-widget_type="heading.default"
                              >
                                <div className="elementor-widget-container">
                                  <h2 className="elementor-heading-title elementor-size-default">
                                    Confidence in Your Investment
                                  </h2>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-02823ad elementor-widget elementor-widget-text-editor"
                                data-id="02823ad"
                                data-element_type="widget"
                                data-widget_type="text-editor.default"
                              >
                                <div className="elementor-widget-container">
                                  Invest in one of our trusted used engines and enjoy
                                  the confidence of knowing. It has been carefully
                                  chosen for its reliability and enhanced power. We
                                  understand that your vehicle’s performance matters,
                                  and our engines are here to meet and exceed your
                                  expectations.{" "}
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-feaaa21 elementor-widget elementor-widget-heading"
                                data-id="feaaa21"
                                data-element_type="widget"
                                data-widget_type="heading.default"
                              >
                                <div className="elementor-widget-container">
                                  <h2 className="elementor-heading-title elementor-size-default">
                                    Premium Selection, Uncompromised Quality
                                  </h2>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-b6905e9 elementor-widget elementor-widget-text-editor"
                                data-id="b6905e9"
                                data-element_type="widget"
                                data-widget_type="text-editor.default"
                              >
                                <div className="elementor-widget-container">
                                  Upgrade your vehicle’s performance without
                                  compromising on quality or reliability. With our
                                  premium selection of used engines, you can trust
                                  Motor Tiger Inc. to provide a driving experience
                                  that surpasses expectations.
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-da813b2 elementor-widget elementor-widget-heading"
                                data-id="da813b2"
                                data-element_type="widget"
                                data-widget_type="heading.default"
                              >
                                <div className="elementor-widget-container">
                                  <h2 className="elementor-heading-title elementor-size-default">
                                    Experience the Difference
                                  </h2>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-2b558c8 elementor-widget elementor-widget-text-editor"
                                data-id="2b558c8"
                                data-element_type="widget"
                                data-widget_type="text-editor.default"
                              >
                                <div className="elementor-widget-container">
                                  Experience the difference with our carefully
                                  selected used engines, designed to provide you with
                                  the power and performance you deserve.{" "}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </section>
              <section
                className="elementor-section elementor-top-section elementor-element elementor-element-ae86474 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                data-id="ae86474"
                data-element_type="section"
                data-settings='{"background_background":"classic","_ha_eqh_enable":false}'
              >
                <div className="elementor-background-overlay" />
                <div className="elementor-container elementor-column-gap-default">
                  <div
                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-dfd9e79"
                    data-id="dfd9e79"
                    data-element_type="column"
                  >
                    <div className="elementor-widget-wrap elementor-element-populated">
                      <div
                        className="elementor-element elementor-element-71c7740 elementor-align-center elementor-widget__width-auto elementor-widget elementor-widget-button"
                        data-id="71c7740"
                        data-element_type="widget"
                        data-widget_type="button.default"
                      >
                        <div className="elementor-widget-container">
                          <div className="elementor-button-wrapper">
                            <a
                              className="elementor-button elementor-button-link elementor-size-sm"
                              href="index.html@p=4471.html#"
                            >
                              <span className="elementor-button-content-wrapper">
                                <span className="elementor-button-text">
                                  CALL US NOW
                                </span>
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div
                        className="elementor-element elementor-element-3999392 elementor-align-center elementor-widget__width-auto elementor-widget elementor-widget-button"
                        data-id={3999392}
                        data-element_type="widget"
                        data-widget_type="button.default"
                      >
                        <div className="elementor-widget-container">
                          <div className="elementor-button-wrapper">
                            <a
                              className="elementor-button elementor-button-link elementor-size-sm"
                              href="index.html@p=4471.html#"
                            >
                              <span className="elementor-button-content-wrapper">
                                <span className="elementor-button-text">
                                  BOOK YOUR TRANSMISSION
                                </span>
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          {/* #page */}
        </div>
        {/* #page */}
      </>
  </div>
  );
};

export default UsedEngines;