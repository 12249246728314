import React from 'react';

const RebuiltTransmissions = () => {
  return (
    <div>
      <>
        <div className="site-primary" id="main">
          <div className="site-content" id="content">
            <div
              data-elementor-type="wp-page"
              data-elementor-id={4659}
              className="elementor elementor-4659"
            >
              <section
                className="elementor-section elementor-top-section elementor-element elementor-element-3c8bbc4 elementor-section-height-min-height elementor-section-boxed elementor-section-height-default elementor-section-items-middle"
                data-id="3c8bbc4"
                data-element_type="section"
                data-settings='{"background_background":"classic","_ha_eqh_enable":false}'
              >
                <div className="elementor-background-overlay" />
                <div className="elementor-container elementor-column-gap-default">
                  <div
                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-bc4ea1b"
                    data-id="bc4ea1b"
                    data-element_type="column"
                  >
                    <div className="elementor-widget-wrap elementor-element-populated">
                      <div
                        className="elementor-element elementor-element-1927293 elementor-widget elementor-widget-heading"
                        data-id={1927293}
                        data-element_type="widget"
                        data-widget_type="heading.default"
                      >
                        <div className="elementor-widget-container">
                          <style
                            dangerouslySetInnerHTML={{
                              __html:
                                "\n\t\t\t\t\t\t\t\t\t\t\t\t/*! elementor - v3.22.0 - 26-06-2024 */\n\t\t\t\t\t\t\t\t\t\t\t\t.elementor-heading-title {\n\t\t\t\t\t\t\t\t\t\t\t\t\tpadding: 0;\n\t\t\t\t\t\t\t\t\t\t\t\t\tmargin: 0;\n\t\t\t\t\t\t\t\t\t\t\t\t\tline-height: 1\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-heading .elementor-heading-title[class*=elementor-size-]>a {\n\t\t\t\t\t\t\t\t\t\t\t\t\tcolor: inherit;\n\t\t\t\t\t\t\t\t\t\t\t\t\tfont-size: inherit;\n\t\t\t\t\t\t\t\t\t\t\t\t\tline-height: inherit\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-heading .elementor-heading-title.elementor-size-small {\n\t\t\t\t\t\t\t\t\t\t\t\t\tfont-size: 15px\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-heading .elementor-heading-title.elementor-size-medium {\n\t\t\t\t\t\t\t\t\t\t\t\t\tfont-size: 19px\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-heading .elementor-heading-title.elementor-size-large {\n\t\t\t\t\t\t\t\t\t\t\t\t\tfont-size: 29px\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-heading .elementor-heading-title.elementor-size-xl {\n\t\t\t\t\t\t\t\t\t\t\t\t\tfont-size: 39px\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-heading .elementor-heading-title.elementor-size-xxl {\n\t\t\t\t\t\t\t\t\t\t\t\t\tfont-size: 59px\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t\t\t"
                            }}
                          />
                          <h2 className="elementor-heading-title elementor-size-default">
                            Rebuilt Transmission
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section
                className="elementor-section elementor-top-section elementor-element elementor-element-d7ef547 elementor-section-height-min-height elementor-section-boxed elementor-section-height-default elementor-section-items-middle"
                data-id="d7ef547"
                data-element_type="section"
                data-settings='{"_ha_eqh_enable":false}'
              >
                <div className="elementor-container elementor-column-gap-default">
                  <div
                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-c1c7511"
                    data-id="c1c7511"
                    data-element_type="column"
                  >
                    <div className="elementor-widget-wrap elementor-element-populated">
                      <section
                        className="elementor-section elementor-inner-section elementor-element elementor-element-f55027c elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                        data-id="f55027c"
                        data-element_type="section"
                        data-settings='{"_ha_eqh_enable":false}'
                      >
                        <div className="elementor-container elementor-column-gap-default">
                          <div
                            className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-d45127c"
                            data-id="d45127c"
                            data-element_type="column"
                          >
                            <div className="elementor-widget-wrap elementor-element-populated">
                              <div
                                className="elementor-element elementor-element-2fdf3a1 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                                data-id="2fdf3a1"
                                data-element_type="widget"
                                data-widget_type="icon-list.default"
                              >
                                <div className="elementor-widget-container">
                                  <link
                                    rel="stylesheet"
                                    href="../assets/files/plugins/elementor/assets/css/widget-icon-list.min.css"
                                  />
                                  <ul className="elementor-icon-list-items">
                                    <li className="elementor-icon-list-item">
                                      <a href="/used-engines">
                                        <span className="elementor-icon-list-icon">
                                          <svg
                                            aria-hidden="true"
                                            className="e-font-icon-svg e-fas-dot-circle"
                                            viewBox="0 0 512 512"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm80 248c0 44.112-35.888 80-80 80s-80-35.888-80-80 35.888-80 80-80 80 35.888 80 80z"></path>
                                          </svg>{" "}
                                        </span>
                                        <span className="elementor-icon-list-text">
                                          Used Engines
                                        </span>
                                      </a>
                                    </li>
                                    <li className="elementor-icon-list-item">
                                      <a href="/used-transmissions">
                                        <span className="elementor-icon-list-icon">
                                          <svg
                                            aria-hidden="true"
                                            className="e-font-icon-svg e-fas-dot-circle"
                                            viewBox="0 0 512 512"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm80 248c0 44.112-35.888 80-80 80s-80-35.888-80-80 35.888-80 80-80 80 35.888 80 80z"></path>
                                          </svg>{" "}
                                        </span>
                                        <span className="elementor-icon-list-text">
                                          Used Transmissions
                                        </span>
                                      </a>
                                    </li>
                                    <li className="elementor-icon-list-item">
                                      <a href="/used-auto-spare-parts">
                                        <span className="elementor-icon-list-icon">
                                          <svg
                                            aria-hidden="true"
                                            className="e-font-icon-svg e-fas-dot-circle"
                                            viewBox="0 0 512 512"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm80 248c0 44.112-35.888 80-80 80s-80-35.888-80-80 35.888-80 80-80 80 35.888 80 80z"></path>
                                          </svg>{" "}
                                        </span>
                                        <span className="elementor-icon-list-text">
                                          Used Auto Parts
                                        </span>
                                      </a>
                                    </li>
                                    <li className="elementor-icon-list-item">
                                      <a href="/rebuilt-engines">
                                        <span className="elementor-icon-list-icon">
                                          <svg
                                            aria-hidden="true"
                                            className="e-font-icon-svg e-fas-dot-circle"
                                            viewBox="0 0 512 512"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm80 248c0 44.112-35.888 80-80 80s-80-35.888-80-80 35.888-80 80-80 80 35.888 80 80z"></path>
                                          </svg>{" "}
                                        </span>
                                        <span className="elementor-icon-list-text">
                                          Rebuilt Engines
                                        </span>
                                      </a>
                                    </li>
                                    <li className="elementor-icon-list-item">
                                      <a href="/rebuilt-transmissions">
                                        <span className="elementor-icon-list-icon">
                                          <svg
                                            aria-hidden="true"
                                            className="e-font-icon-svg e-fas-dot-circle"
                                            viewBox="0 0 512 512"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm80 248c0 44.112-35.888 80-80 80s-80-35.888-80-80 35.888-80 80-80 80 35.888 80 80z"></path>
                                          </svg>{" "}
                                        </span>
                                        <span className="elementor-icon-list-text">
                                          Rebuilt Transmissions
                                        </span>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-a99b62a elementor-widget elementor-widget-image"
                                data-id="a99b62a"
                                data-element_type="widget"
                                data-widget_type="image.default"
                              >
                                <div className="elementor-widget-container">
                                  <style
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        '\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t/*! elementor - v3.22.0 - 26-06-2024 */\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-image {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\ttext-align: center\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-image a {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tdisplay: inline-block\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-image a img[src$=".svg"] {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\twidth: 48px\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-image img {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tvertical-align: middle;\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tdisplay: inline-block\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t'
                                    }}
                                  />{" "}
                                  <img
                                    decoding="async"
                                    width={659}
                                    height={423}
                                    src="../assets/files/uploads/2024/02/image-1.png"
                                    className="attachment-large size-large wp-image-4563"
                                    alt=""
                                    srcSet="../assets/files/uploads/2024/02/image-1.png 659w, ../assets/files/uploads/2024/02/image-1-300x193.png 300w, ../assets/files/uploads/2024/02/image-1-600x385.png 600w, ../assets/files/uploads/2024/02/image-1-84x54.png 84w"
                                    sizes="(max-width: 659px) 100vw, 659px"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-4692f38"
                            data-id="4692f38"
                            data-element_type="column"
                          >
                            <div className="elementor-widget-wrap elementor-element-populated">
                              <div
                                className="elementor-element elementor-element-d73bfe7 elementor-widget elementor-widget-heading"
                                data-id="d73bfe7"
                                data-element_type="widget"
                                data-widget_type="heading.default"
                              >
                                <div className="elementor-widget-container">
                                  <h2 className="elementor-heading-title elementor-size-default">
                                    Rebuilt Transmission
                                  </h2>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-923d4e0 elementor-widget elementor-widget-text-editor"
                                data-id="923d4e0"
                                data-element_type="widget"
                                data-widget_type="text-editor.default"
                              >
                                <div className="elementor-widget-container">
                                  <style
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t/*! elementor - v3.22.0 - 26-06-2024 */\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-text-editor.elementor-drop-cap-view-stacked .elementor-drop-cap {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tbackground-color: #69727d;\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tcolor: #fff\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-text-editor.elementor-drop-cap-view-framed .elementor-drop-cap {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tcolor: #69727d;\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tborder: 3px solid;\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tbackground-color: transparent\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tmargin-top: 8px\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap-letter {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\twidth: 1em;\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\theight: 1em\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-text-editor .elementor-drop-cap {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tfloat: left;\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\ttext-align: center;\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tline-height: 1;\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tfont-size: 50px\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-text-editor .elementor-drop-cap-letter {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tdisplay: inline-block\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                    }}
                                  />{" "}
                                  Welcome to Motor Tiger Inc., where reliable
                                  performance meets exceptional quality. Upgrade your
                                  vehicle’s transmission with our meticulously rebuilt
                                  transmissions, restored to like-new condition for a
                                  driving experience you can trust.
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-37ee5ce elementor-widget elementor-widget-heading"
                                data-id="37ee5ce"
                                data-element_type="widget"
                                data-widget_type="heading.default"
                              >
                                <div className="elementor-widget-container">
                                  <h2 className="elementor-heading-title elementor-size-default">
                                    Rebuilt to Perfection{" "}
                                  </h2>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-2df78f1 elementor-widget elementor-widget-text-editor"
                                data-id="2df78f1"
                                data-element_type="widget"
                                data-widget_type="text-editor.default"
                              >
                                <div className="elementor-widget-container">
                                  Discover our selection of rebuilt transmissions,
                                  carefully refurbished to meet or exceed industry
                                  standards for quality and durability. Our commitment
                                  to excellence means you can rely on a transmission
                                  that has undergone a comprehensive restoration
                                  process, ensuring it is in excellent condition and
                                  ready to deliver optimal performance.{" "}
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-429312b elementor-widget elementor-widget-heading"
                                data-id="429312b"
                                data-element_type="widget"
                                data-widget_type="heading.default"
                              >
                                <div className="elementor-widget-container">
                                  <h2 className="elementor-heading-title elementor-size-default">
                                    Craftsmanship and Quality
                                  </h2>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-9ae10be elementor-widget elementor-widget-text-editor"
                                data-id="9ae10be"
                                data-element_type="widget"
                                data-widget_type="text-editor.default"
                              >
                                <div className="elementor-widget-container">
                                  Trust in the reliability of our rebuilt
                                  transmissions, expertly crafted using high-quality
                                  components and precise craftsmanship. Our skilled
                                  technicians pay attention to every detail, ensuring
                                  each transmission meets our stringent quality
                                  standards before reaching your vehicle.{" "}
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-a25768f elementor-widget elementor-widget-heading"
                                data-id="a25768f"
                                data-element_type="widget"
                                data-widget_type="heading.default"
                              >
                                <div className="elementor-widget-container">
                                  <h2 className="elementor-heading-title elementor-size-default">
                                    Benefits of Rebuilt Transmissions
                                  </h2>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-b05db51 elementor-widget elementor-widget-text-editor"
                                data-id="b05db51"
                                data-element_type="widget"
                                data-widget_type="text-editor.default"
                              >
                                <div className="elementor-widget-container">
                                  Enjoy the benefits of a rebuilt transmission,
                                  including improved performance, smoother shifting,
                                  and enhanced durability. Investing in a rebuilt
                                  transmission from us means investing in a component
                                  that will contribute to a more efficient and
                                  reliable driving experience.{" "}
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-5f06069 elementor-widget elementor-widget-heading"
                                data-id="5f06069"
                                data-element_type="widget"
                                data-widget_type="heading.default"
                              >
                                <div className="elementor-widget-container">
                                  <h2 className="elementor-heading-title elementor-size-default">
                                    Peace of Mind Investment
                                  </h2>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-b65b50b elementor-widget elementor-widget-text-editor"
                                data-id="b65b50b"
                                data-element_type="widget"
                                data-widget_type="text-editor.default"
                              >
                                <div className="elementor-widget-container">
                                  Invest in a rebuilt transmission from Motor Tiger
                                  Inc. and experience the peace of mind that comes
                                  with a reliable and professionally restored
                                  component. Our commitment to quality extends in
                                  providing you with a cost-effective solution without
                                  compromising on the performance you expect.{" "}
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-be99e4a elementor-widget elementor-widget-heading"
                                data-id="be99e4a"
                                data-element_type="widget"
                                data-widget_type="heading.default"
                              >
                                <div className="elementor-widget-container">
                                  <h2 className="elementor-heading-title elementor-size-default">
                                    Extended Lifespan
                                  </h2>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-46e2ca9 elementor-widget elementor-widget-text-editor"
                                data-id="46e2ca9"
                                data-element_type="widget"
                                data-widget_type="text-editor.default"
                              >
                                <div className="elementor-widget-container">
                                  We take pride in offering rebuilt transmissions that
                                  are as good as new, providing you with a
                                  cost-effective solution to extend the lifespan of
                                  your vehicle. Enjoy a smoother and more efficient
                                  driving experience, knowing that you have a
                                  high-quality rebuilt transmission under the hood.{" "}
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-c09bf07 elementor-widget elementor-widget-heading"
                                data-id="c09bf07"
                                data-element_type="widget"
                                data-widget_type="heading.default"
                              >
                                <div className="elementor-widget-container">
                                  <h2 className="elementor-heading-title elementor-size-default">
                                    Expert Guidance
                                  </h2>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-0208e70 elementor-widget elementor-widget-text-editor"
                                data-id="0208e70"
                                data-element_type="widget"
                                data-widget_type="text-editor.default"
                              >
                                <div className="elementor-widget-container">
                                  Our knowledgeable team is ready to assist you in
                                  finding the right rebuilt transmission for your
                                  vehicle, ensuring a seamless process. Trust our
                                  expertise to guide you through the selection,
                                  ensuring that you receive a transmission tailored to
                                  your vehicle’s need.{" "}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </section>
              <section
                className="elementor-section elementor-top-section elementor-element elementor-element-4cacf0c elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                data-id="4cacf0c"
                data-element_type="section"
                data-settings='{"background_background":"classic","_ha_eqh_enable":false}'
              >
                <div className="elementor-background-overlay" />
                <div className="elementor-container elementor-column-gap-default">
                  <div
                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-d5879a8"
                    data-id="d5879a8"
                    data-element_type="column"
                  >
                    <div className="elementor-widget-wrap elementor-element-populated">
                      <div
                        className="elementor-element elementor-element-d367cc9 elementor-align-center elementor-widget__width-auto elementor-widget elementor-widget-button"
                        data-id="d367cc9"
                        data-element_type="widget"
                        data-widget_type="button.default"
                      >
                        <div className="elementor-widget-container">
                          <div className="elementor-button-wrapper">
                            <a
                              className="elementor-button elementor-button-link elementor-size-sm"
                              href="/rebuilt-transmissions#"
                            >
                              <span className="elementor-button-content-wrapper">
                                <span className="elementor-button-text">
                                  CALL US NOW
                                </span>
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div
                        className="elementor-element elementor-element-caba819 elementor-align-center elementor-widget__width-auto elementor-widget elementor-widget-button"
                        data-id="caba819"
                        data-element_type="widget"
                        data-widget_type="button.default"
                      >
                        <div className="elementor-widget-container">
                          <div className="elementor-button-wrapper">
                            <a
                              className="elementor-button elementor-button-link elementor-size-sm"
                              href="/rebuilt-transmissions#"
                            >
                              <span className="elementor-button-content-wrapper">
                                <span className="elementor-button-text">
                                  BOOK YOUR TRANSMISSION
                                </span>
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          {/* #page */}
        </div>
        {/* #page */}
      </>
  </div>
  );
};

export default RebuiltTransmissions;