import React from 'react';

function ThankYouPage() {
    return (
        <div style={styles.container}>
            <div style={styles.checkmarkCircle}>
                <span style={styles.checkmark}>✓</span>
            </div>
            <h1 style={styles.title}>Thank You For Contacting Us!</h1>
            <p style={styles.message}>We have received your message<br />We'll reach you out</p>
            <div style={styles.contact}>
                <a href="tel:+1 (855) 253-1634" style={styles.contactLink}>+1 (855) 253-1634</a>
            </div>
        </div>
    );
}

const styles = {
    container: {
        fontFamily: 'Arial, sans-serif',
        minHeight: '100vh',
        background: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(https://png.pngtree.com/thumb_back/fh260/background/20230411/pngtree-nature-forest-sun-ecology-image_2256183.jpg) no-repeat center center',
        backgroundSize: 'cover',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        textAlign: 'center',
    },
    checkmarkCircle: {
        width: '80px',
        height: '80px',
        backgroundColor: '#4CAF50',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '20px',
    },
    checkmark: {
        color: 'white',
        fontSize: '40px',
    },
    title: {
        fontSize: '28px',
        marginBottom: '15px',
    },
    message: {
        fontSize: '18px',
        marginBottom: '20px',
        lineHeight: '1.5',
    },
    contact: {
        fontSize: '24px',
        fontWeight: 'bold',
        cursor: 'pointer',
    },
    contactLink: {
        fontSize: '24px',
        fontWeight: 'bold',
        color: '#fff',
        textDecoration: 'none',
    },
};

export default ThankYouPage;